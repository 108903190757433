import * as React from 'react';

import { ButtonGroup, Button } from '@progress/kendo-react-buttons';
import { DateRangePicker } from '@progress/kendo-react-dateinputs';

import { useLocalization } from '@progress/kendo-react-intl';
import { filterBy } from '@progress/kendo-data-query';

import { Popup } from '@progress/kendo-react-popup';

import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { Error } from '@progress/kendo-react-labels';
import { Input } from '@progress/kendo-react-inputs';
import { TextArea } from '@progress/kendo-react-inputs';

import { Grid, Column, ColumnMenu } from './../components/Grid';

import { FullNameCell, FlagCell, OnlineCell, RatingCell, EngagementCell, CurrencyCell } from './../components/GridCells';



 class ManageAgent extends React.Component { 
     


 constructor(props)
	{
		
		
		// for the login check system here
		const checklogin = localStorage.getItem('custid');
			if (typeof checklogin === "undefined") {
				window.location.href="/#login1"; 				
			} else {
				if(checklogin!="" && checklogin!=null) { } else {
						window.location.href="/#login"; 
					}
			}
		// for the login check system here
		
		 document.title="All Agents";
		super(props);
			this.state = {
				createmessage		:	"",
				error		:	null,
				show		:	false,
				isLoaded	:	false,
				items		: 	[]
			};
		
			this.handleSubmit = this.handleSubmit.bind(this);
			
	}
      
  componentDidMount()
	{
			this.setState({ show: false });
			this.fetchallcustomers();
	}


handleSubmit(dataItem)
	{
		 // creates entity 
		 
		 this.setState({
            createmessage: "Submitting your Request.."
          });
		 
		 
			if(dataItem.password!=dataItem.confirm)
				{
					 this.setState({
						createmessage: <Error>Password and Confirm Password must be same.</Error>
					  });
					return false;
				}
				
			if (typeof dataItem.email !== "undefined")
				{
					var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
					
						if (!pattern.test(dataItem.email))
							{
								 this.setState({
									createmessage: <Error>Please enter valid email address.</Error>
								  });
								return false;
							}

				}
		 
			let postData = new FormData();

				postData.append('name'		,	dataItem.name);
				postData.append('email'		,	dataItem.email);
				postData.append('mobile'	,	dataItem.mobile);
				postData.append('password'	,	dataItem.password);
				postData.append('confirm'	, 	dataItem.confirm);
				

		 const requestOptions 	=	{
							method	:	'POST',
							body	:	postData
						}; 
		 
		 fetch(global.config.apiurls.createagent, requestOptions)
		 .then(response => response.json())
		 .then(response => {
				this.fetchallcustomers();
				if(response.status)
					{
						this.showcustform();
						this.setState({ createmessage: response.message });
					} else {
						const temp = <Error>{response.message}</Error>
						this.setState({ createmessage: temp });
					}
			})
		.catch(err => {
			console.log(err);
				 this.setState({
					createmessage: <Error>There is an problem, Submitting your request. Please try again later.</Error>
				  });
		});
		
		// alert(JSON.stringify(dataItem, null, 2));
	}

     render() {
    const { error, isLoaded, items , createmessage } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      return (
       
        <div className="card-container" >
			<div className="card-component" >		
			
				<button primary={true} className={this.state.show ? 'k-outline k-button' : 'k-primary k-button'} onClick={this.showcustform} ref={(button) => { this.anchor = button; }} >
                    {this.state.show ? 'Close Add Agent' : ' Add Agent'}
				</button>
				
                <Popup anchor={this.anchor} show={this.state.show} popupClass={
'popup-content'} >
                    
					
					
			 <Form
            onSubmit={this.handleSubmit}
            render={(formRenderProps) => (
                <FormElement style={{maxWidth: 650}}>
                    <fieldset className={'k-form-fieldset'}>
                        
                        <div className="form-group">
                            <Field name={'name'} component={Input} label={'Name*'} required={true} maxLength={80} />
                        </div>

                        <div className="form-group">
                            <Field name={"email"} type={"email"} component={Input} label={"Email*"} required={true} maxLength={80} />
                        </div>

                        <div className="form-group">
                            <Field name={"mobile"} type={"text"} component={Input} label={"Mobile*"} maxLength={12} />
                        </div>
						
                        <div className="form-group">
                            <Field name={"password"} type={"password"} component={Input} label={"Password*"} maxLength={15} />
                        </div>
						
                        <div className="form-group">
                            <Field name={"confirm"} type={"password"} component={Input} label={"Confirm Password*"} maxLength={15} />
                        </div>
						
                    </fieldset>
					{createmessage}
                    <div className="k-form-buttons">
                        <button
                            type={'submit'}
                            className="k-button k-primary"
                            disabled={!formRenderProps.allowSubmit}
                        >
                            Submit
                        </button>
                    </div>
                </FormElement>
            )}
        />
		
					
					
                </Popup>
			
				<Grid data={[ ...items ]} >
					<Column field="agnid" title="Customer ID"  />
					<Column field="name" title="Name"  />
					<Column field="email" title="Email" />
					<Column field="mobile" title="Mobile" />
					<Column field="added" title="Created on" />
				</Grid>


			</div>
		</div>
	   
      );
    }
  }

   showcustform = () => {
        this.setState({ show: !this.state.show });
    }
	
	
   fetchallcustomers = () => {
        
		
    fetch(global.config.apiurls.listagent)
      .then(res => res.json())
      .then(
        (result) => {
			const alldata = [];
			
			const counter = result.data.length;
		
				for (let i = 0; i < counter; i++)
					{
						const item = result.data[i];
							alldata.push(item);
					}
			
          this.setState({
            isLoaded: true,
            items: alldata // result.items
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
		
    }
  
  
} 

export default ManageAgent;