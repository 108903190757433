
import * as React from 'react';

import { ButtonGroup, Button } from '@progress/kendo-react-buttons';
import { DateRangePicker } from '@progress/kendo-react-dateinputs';

import { useLocalization } from '@progress/kendo-react-intl';
import { filterBy } from '@progress/kendo-data-query';

import { Grid, Column, ColumnMenu } from './../components/Grid';
import { Chart } from './../components/Chart';
import { FullNameCell, FlagCell, OnlineCell, RatingCell, EngagementCell, CurrencyCell } from './../components/GridCells';

import { AppContext } from './../AppContext'

import { employees } from './../resources/employees';
import { teams } from './../resources/teams';
import { orders } from './../resources/orders';

const Dashboard = () => {
	
	window.location.href="/#manageticket"; 
		// for the login check system here
		const checklogin = localStorage.getItem('custid');
			if (typeof checklogin === "undefined") {
				window.location.href="/#login1"; 				
			} else {
				if(checklogin!="" && checklogin!=null) { } else {
						window.location.href="/#login"; 
					}
			}
		// for the login check system here
	
    const [data, setData] = React.useState(employees);
    const [isTrend, setIsTrend] = React.useState(true);
    const [isMyTeam, setIsMyTeam] = React.useState(true);
    const localizationService = useLocalization();

    const isChartChangeRef = React.useRef(false);
    const onChartRefresh = React.useCallback(
        () => null,
        []
    );

    React.useEffect(() => {
        isChartChangeRef.current = false;
    });

    const { teamId } = React.useContext(AppContext);
    const gridFilterExpression = isMyTeam ? {
            logic: "and",
            filters: [{ field: "teamId", operator: "eq", value: teamId }]
        } : null;

    const [range, setRange] = React.useState({
        start: new Date('2020-01-01T21:00:00.000Z'),
        end: new Date('2020-04-29T21:00:00.000Z')
    });
    const onRangeChange = React.useCallback(
        (event) => {
            setRange({
                start: event.value.start,
                end: event.value.end
            })
        },
        [setRange]
    );
    const trendOnClick = React.useCallback(
        () => {
            isChartChangeRef.current = true;
            setIsTrend(true);
        },
        [setIsTrend]
    );
    const volumeOnClick = React.useCallback(
        () => {
            isChartChangeRef.current = true;
            setIsTrend(false);
        },
        [setIsTrend]
    );
    const myTeamOnClick = React.useCallback(
        () => setIsMyTeam(true),
        [setIsMyTeam]
    );
    const allTeamOnClick = React.useCallback(
        () => setIsMyTeam(false),
        [setIsMyTeam]
    );

    return (
        <div id="Dashboard" className="dashboard-page main-content">
            <div className="card-container grid">
                <h3 className="card-title">{localizationService.toLanguageString('custom.teamEfficiency')}</h3>
                <div className="card-buttons">
                    <ButtonGroup>
                       {/* <Button togglable={true} selected={!isTrend} onClick={trendOnClick}>
                            {localizationService.toLanguageString('custom.trend')}
                             </Button>
                        <Button togglable={true} selected={isTrend} onClick={volumeOnClick}>
                            {localizationService.toLanguageString('custom.volume')}
                        </Button>*/}
                    </ButtonGroup>
                </div>
                <div className="card-ranges">
                    <DateRangePicker value={range} onChange={onRangeChange} />
                </div>
                <div className="card-component">
                    <Chart
                        data={orders}
                        filterStart={range.start}
                        filterEnd={range.end}
                        groupByField={'teamID'}
                        groupResourceData={teams}
                        groupTextField={'teamName'}
                        groupColorField={'teamColor'}
                        seriesCategoryField={'orderDate'}
                        seriesField={'orderTotal'}
                        seriesType={isTrend ? 'line' : 'column'}
                        onRefresh={isChartChangeRef.current ? null : onChartRefresh}
                    />
                </div>
            </div>
            <div className="card-container grid">
                <h3 className="card-title">{localizationService.toLanguageString('custom.requestTicket')}</h3>
                <div className="card-buttons">
                    <ButtonGroup>
                        <Button togglable={true} selected={isMyTeam} onClick={myTeamOnClick}>
                            {localizationService.toLanguageString('custom.myTicket')}
                        </Button>
                        <Button togglable={true} selected={!isMyTeam} onClick={allTeamOnClick}>
                            {localizationService.toLanguageString('custom.allTicket')}
                        </Button>
                    </ButtonGroup>
                </div>
                <span></span>
                <div className="card-component">
                    <Grid data={filterBy(data, gridFilterExpression)} style={{ height: 450 }} onDataChange={data => setData(data)}>
                            <Column field={'TicketId'} title={localizationService.toLanguageString('custom.ticketId')} columnMenu={ColumnMenu} style={{width: 5 + '%'}}cell={CurrencyCell} />
                            <Column field={'jobTitle'} title={localizationService.toLanguageString('custom.jobTitle')} columnMenu={ColumnMenu} style={{width: 30 + '%'}} />
                            <Column field={'fullName'} title={localizationService.toLanguageString('custom.contactName')} columnMenu={ColumnMenu}style={{width: 20 + '%'}}cell={FullNameCell} />
                            <Column field={'isOnline'} title={localizationService.toLanguageString('custom.status')} columnMenu={ColumnMenu} style={{width: 10 + '%'}}cell={OnlineCell} filter={'boolean'} />
                            <Column field={'jobTitle'} title={localizationService.toLanguageString('custom.priority')} columnMenu={ColumnMenu} style={{width: 15 + '%'}} />
                      
                       
                    </Grid>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;

