import * as React from 'react';

import { ButtonGroup, Button } from '@progress/kendo-react-buttons';
import { DateRangePicker } from '@progress/kendo-react-dateinputs';

import { useLocalization } from '@progress/kendo-react-intl';
import { filterBy } from '@progress/kendo-data-query';

import { Popup } from '@progress/kendo-react-popup';

import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { Error } from '@progress/kendo-react-labels';
import { Input } from '@progress/kendo-react-inputs';
import { TextArea } from '@progress/kendo-react-inputs';

import { Grid, Column, ColumnMenu } from './../components/Grid';

import { FullNameCell, FlagCell, OnlineCell, RatingCell, EngagementCell, CurrencyCell } from './../components/GridCells';



 class Settings extends React.Component { 
     


 constructor(props)
	{
		const checklogin = localStorage.getItem('custid');
			if (typeof checklogin === "undefined") {
				window.location.href="/#login1"; 				
			} else {
				if(checklogin!="" && checklogin!=null) { } else {
						window.location.href="/#login"; 
					}
			}
		
		 document.title="SMTP Setting";
		super(props);
			this.state = {
				createmessage		:	"",
				error		:	null,
				show		:	false,
				isLoaded	:	false,
				settingdata		: 	[]
			};
		
			this.handleSubmit = this.handleSubmit.bind(this);
			
	}
      
  componentDidMount()
	{
		this.getsmtpsettings();
			this.setState({ show: false });
	}
	
   getsmtpsettings = () => {
				fetch(global.config.apiurls.getsmtpsettings)
				  .then(res => res.json())
				  .then(
					(result) => {
					  this.setState({
						isLoaded: true,
						settingemail	:	result.data.email,
						settingpassword	:	result.data.password,
						settingmailurl	:	result.data.mailurl,
						settingmailport	:	result.data.mailport
					  });
					},
					// Note: it's important to handle errors here
					// instead of a catch() block so that we don't swallow
					// exceptions from actual bugs in components.
					(error) => {
					  this.setState({
						isLoaded: true,
						error
					  });
					}
				  )
					
				}

for_settingemail(value){ this.setState({ settingemail: value }); }
for_settingpassword(value){ this.setState({ settingpassword: value }); }
for_settingmailurl(value){ this.setState({ settingmailurl: value }); }
for_settingmailport(value){ this.setState({ settingmailport: value }); }

handleSubmit(dataItem)
	{
		 // creates entity 
		 
		 this.setState({
            createmessage: "Submitting your Request.."
          });
		 
		 
		 const { settingemail , settingpassword, settingmailurl ,settingmailport } = this.state;
				
			if (typeof dataItem.email !== "undefined")
				{
					var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
					
						if (!pattern.test(dataItem.email))
							{
								 this.setState({
									createmessage: <Error>Please enter valid email address.</Error>
								  });
								return false;
							}

				}
		 
			let postData = new FormData();

				postData.append('email'		,	settingemail);
				postData.append('password'	,settingpassword);
				postData.append('mailurl'	,	settingmailurl);
			//	postData.append('mailport'	,	dataItem.mailport);
				postData.append('mailport'	,	settingmailport);
				

		 const requestOptions 	=	{
							method	:	'POST',
							body	:	postData
						}; 
		 
		 fetch(global.config.apiurls.smtpsettings, requestOptions)
		 .then(response => response.json())
		 .then(response => {
				if(response.status)
					{
						this.showcustform();
						this.setState({ createmessage: response.message });
					} else {
						const temp = <Error>{response.message}</Error>
						this.setState({ createmessage: temp });
					}
			})
		.catch(err => {
			console.log(err);
				 this.setState({
					createmessage: <Error>There is an problem, Submitting your request. Please try again later.</Error>
				  });
		});
		
		// alert(JSON.stringify(dataItem, null, 2));
	}

     render() {
    
	const { error, isLoaded, settingemail , settingpassword, settingmailurl ,settingmailport , createmessage } = this.state;
      
      return (
       
        <div className="card-container" >
			<div className="card-component" >		
			
				 
					
					
			 <Form
            onSubmit={this.handleSubmit}
            render={(formRenderProps) => (
                <FormElement style={{maxWidth: 650}}>
                    <fieldset className={'k-form-fieldset'}>
                        
                        <div className="form-group">
                            <Field name={'email'} component={Input} value={settingemail} label={'Email*'} required={true} maxLength={80} onChange={e => this.for_settingemail(e.target.value)} />
                        </div>

                        <div className="form-group">
                            <Field name={"password"} type={"password"} value={settingpassword} component={Input} label={"Password*"} required={true} maxLength={80} onChange={e => this.for_settingpassword(e.target.value)} />
                        </div>

                        <div className="form-group">
                            <Field name={"mailurl"} type={"text"} component={Input} value={settingmailurl} label={"Host URL*"} maxLength={255} onChange={e => this.for_settingmailurl(e.target.value)} />
                        </div>
						
                        <div className="form-group">
                            <Field name={"mailport"} type={"number"} component={Input} value={settingmailport} label={"Mail Port*"} maxLength={15} onChange={e => this.for_settingmailport(e.target.value)} />
                        </div>
						
                    </fieldset>
					{createmessage}
                    <div className="k-form-buttons">
                        <button
                            type={'submit'}
                            className="k-button k-primary"
							 disabled={!formRenderProps.allowSubmit} 
                            >
							
                            Submit
                        </button>
                    </div>
                </FormElement>
            )}
        />
		
				

			</div>
		</div>
	   
      );
    
  }

   showcustform = () => {
        this.setState({ show: !this.state.show });
    } 
  
} 

export default Settings;