import * as React from 'react';

import { ButtonGroup, Button } from '@progress/kendo-react-buttons';
import { DateRangePicker } from '@progress/kendo-react-dateinputs';

import { useLocalization } from '@progress/kendo-react-intl';
import { filterBy } from '@progress/kendo-data-query';

import { Popup } from '@progress/kendo-react-popup';

import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { Error } from '@progress/kendo-react-labels';
import { Input } from '@progress/kendo-react-inputs';
import { TextArea } from '@progress/kendo-react-inputs';

import { Grid, Column, ColumnMenu } from './../components/Grid';

import { FullNameCell, FlagCell, OnlineCell, RatingCell, EngagementCell, CurrencyCell } from './../components/GridCells';


// import './../login.css';

 class Logout extends React.Component { 
     


 constructor(props)
	{
		document.title="User Logout";
			localStorage.removeItem('custid');
				localStorage.removeItem('name');
					localStorage.removeItem('usertype');
			window.location.href="/#login";
		super(props);
	}

     render() {
	
      return (
       
        <div className="card-container" >
			<div className="card-component" >
				Logouting you.
			</div>
		</div>
	   
      );
    
  }
 
  
} 

export default Logout;