import * as React from 'react';

import { ButtonGroup, Button } from '@progress/kendo-react-buttons';
import { DateRangePicker } from '@progress/kendo-react-dateinputs';
import { Link } from 'react';
import { useLocalization } from '@progress/kendo-react-intl';
import { filterBy } from '@progress/kendo-data-query';

import { orderBy } from '@progress/kendo-data-query';

import { Grid, Column, ColumnMenu } from './../components/Grid';

import { FullNameCell, FlagCell, OnlineCell, RatingCell, EngagementCell, CurrencyCell } from './../components/GridCells';

const EditCommandCell = props => {
    return (
        <td>
            <button
                className="k-button k-primary"
                onClick={() => props.enterEdit(props.dataItem)}
            >
                View
      </button>
        </td>
    );
};

 class ManageTicket extends React.Component { 
     


 constructor(props) {
	 
	 
		// for the login check system here
		const checklogin = localStorage.getItem('custid');
			if (typeof checklogin === "undefined") {
				window.location.href="/#login1"; 				
			} else {
				if(checklogin!="" && checklogin!=null) { } else {
						window.location.href="/#login"; 
					}
			}
		// for the login check system here
	 
	 document.title="All Tickets";
    super(props);
		this.state = {
			error		:	null,
			isLoaded	:	false,
			items		: 	[],
			sort		:	[ { field: 'ticketnumber', dir: 'desc' } ]
		};
  }
    
  componentDidMount() {
    fetch(global.config.apiurls.allticket)
      .then(res => res.json())
      .then(
        (result) => {
			const alldata = [];
			
			const counter = result.data.length;
		// alert(counter);
    for (let i = 0; i < counter; i++)
		{
			
			const editbtn = <Link to="/react">React</Link>;
			
			const item = result.data[i];
				alldata.push({ticketnumber:item.ticketnumber,summary:item.summary,name:item.name,ticketstatus:item.ticketstatus,issuetype:item.issuetype,createddate:item.createddate,editbtn:editbtn});
		}
			
			// createddate  issuetype name summary  ticketnumber   ticketstatus
		
			
          this.setState({
            isLoaded: true,
            items: alldata // result.items
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

 enterEdit = item => {
        // console.log(item);
			localStorage.setItem('ticketnumber',item.ticketnumber);
				window.location.href="/#viewticket"; 
			
        // alert(item.ticketnumber);
    }

 MyEditCommandCell = props => (
        <EditCommandCell {...props} enterEdit={this.enterEdit} />
    );

     render() {
    const { error, isLoaded, items } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      return (
       
        <div className="card-container" >
        <div className="card-component" >
		
					           
			<Grid   
			
				  data={orderBy(items, this.state.sort)}
					sortable
					sort={this.state.sort}
					onSortChange={(e) => {
						this.setState({
							sort: e.sort
						});
					}}
			
			
			>
                <Column field="ticketnumber" title="ID"  />
                <Column field="summary" title="Ticket Summary"  />
                <Column field="name" title="Customer Name" />
                <Column field="ticketstatus" title="Status" />
                <Column field="issuetype" title="Issue Type" />
                <Column field="createddate" title="Created on" />
                <Column cell={this.MyEditCommandCell} />
            </Grid>
		</div>
		</div>
	   
      );
    }
  } 
} 

export default ManageTicket;

