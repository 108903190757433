import * as React from 'react';

import { ButtonGroup, Button } from '@progress/kendo-react-buttons';
import { DateRangePicker } from '@progress/kendo-react-dateinputs';

import { useLocalization } from '@progress/kendo-react-intl';
import { filterBy } from '@progress/kendo-data-query';

import { Popup } from '@progress/kendo-react-popup';

import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { Error } from '@progress/kendo-react-labels';
import { Input } from '@progress/kendo-react-inputs';
import { TextArea } from '@progress/kendo-react-inputs';

import { Grid, Column, ColumnMenu } from './../components/Grid';

import { FullNameCell, FlagCell, OnlineCell, RatingCell, EngagementCell, CurrencyCell } from './../components/GridCells';


// import './../login.css';

 class AgentLogin extends React.Component { 
     


 constructor(props)
	{
		// window.location.href="/#login";
		 document.title="Agent Login";
		super(props);
			this.state	=	{
					createmessage	:	"",
					logincss		:	global.config.apiurls.logincss
				};
					this.handleSubmit = this.handleSubmit.bind(this);
	}
      
  componentDidMount()
	{
		// 	this.setState({ createmessage: false });
	}


handleSubmit(dataItem)
	{
		 // creates entity 
		 
		 this.setState({
            createmessage: "Submitting your Request.."
          });
		 
				
			if (typeof dataItem.email !== "undefined")
				{
					var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
					
						if (!pattern.test(dataItem.email))
							{
								 this.setState({
									createmessage: <Error>Please enter valid email address.</Error>
								  });
								return false;
							}

				}
		 
			let postData = new FormData();

				postData.append('email'		,	dataItem.email);
				postData.append('password'	,	dataItem.password);

		 const requestOptions 	=	{
							method	:	'POST',
							body	:	postData
						}; 
		 
		 fetch(global.config.apiurls.agentlogin, requestOptions)
		 .then(response => response.json())
		 .then(response => {
				if(response.status)
					{
						localStorage.setItem('custid', response.data.agnid);
						localStorage.setItem('name', response.data.name);
						localStorage.setItem('usertype', 'agent');
						localStorage.setItem('email', response.data.email);
						this.setState({ createmessage: response.message });
							window.location.href="/#";
					} else {
						const temp = <Error>{response.message}</Error>
						this.setState({ createmessage: temp });
					}
			})
		.catch(err => {
			console.log(err);
				 this.setState({
					createmessage: <Error>There is an problem, Submitting your request. Please try again later.</Error>
				  });
		});
		
		// alert(JSON.stringify(dataItem, null, 2));
	}

     render() {
    
	const { createmessage,logincss } = this.state;
      
	  const styles = {
    color: 'blue'
  }
	
      return (
       
        <div className="card-container" >
			<div className="card-component" >		
				 <link rel="stylesheet" href={logincss} />
			 <Form
            onSubmit={this.handleSubmit}
            render={(formRenderProps) => (
                <FormElement style={{maxWidth: 650}}>
                    <fieldset className={'k-form-fieldset'}>
                        
                        <div className="form-group">
                            <Field name={'email'} component={Input} label={'Email*'} required={true} maxLength={80} />
                        </div>

                        <div className="form-group">
                            <Field name={"password"} type={"password"} component={Input} label={"Password*"} required={true} maxLength={80} />
                        </div>
						
                    </fieldset>
					{createmessage}
                    <div className="k-form-buttons">
                        <button
                            type={'submit'}
                            className="k-button k-primary"
                            disabled={!formRenderProps.allowSubmit}
                        >
                            Submit
                        </button>
                    </div>
                </FormElement>
            )}
        />
		
				

			</div>
		</div>
	   
      );
    
  }
 
  
} 

export default AgentLogin;