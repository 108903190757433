import * as React from 'react';

import { useLocalization } from '@progress/kendo-react-intl';
import { useHistory } from "react-router-dom";
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { Button } from '@progress/kendo-react-buttons';

import { Input } from './../components/form/Input';
import { MaskedTextBox } from './../components/form/MaskedTextBox';
import { DropDownList } from './../components/form/DropDownList';
import { Editor } from './../components/form/Editor';
import { Upload } from './../components/form/Upload';
import { RadioGroup } from './../components/form/RadioGroup';
import { Switch } from './../components/form/Switch';

import { AppContext } from './../AppContext'

import { countries } from './../resources/countries';
import { teams } from './../resources/teams';

import { requiredValidator, emailValidator, phoneValidator, biographyValidator } from './../validators'

const countriesData = countries.map(country => country.name);
const teamsData = teams.map(team => ({
    value: team.teamID,
    label: team.teamName
}));

const Profile = () => {
        const {languageId, onLanguageChange, onProfileChange, ...formValues} = React.useContext(AppContext);
        const localizationService = useLocalization();
        const history = useHistory();

        const onSubmit = React.useCallback(
            (dataItem) => {
                onProfileChange({dataItem});

                history.push('/');
            },
            [onProfileChange, history]
        );

        const onCancelClick = React.useCallback(
            () => {
                history.push('/');
            },
            [history]
        );

        return (
            <div id="Profile" className="profile-page main-content">
                <div className="card-container">
                    <div className="card-component">
                         <div className="section-1">
                        <h1>FAQ's</h1>
                    </div>
                    <div className="section-2">
					<br />
					<br />
                        <p>
                            <h3>Q1: How can I create Ticket There?</h3><br />
							<small>You can just go to View Ticket and create the Ticket from ther.</small>
                        </p>
						
					<hr />
					
                        <p>
                            <h3>Q2: Are McDonald's veggie goujons gluten free?</h3><br />
							<small>Yes, but only without the bun of course. The  Pepper & Pesto Goujon itself has no gluten containing ingredients and is cooked in a separate fryer.</small>
                        </p>	
					<hr />
						
                        <p>
                            <h3>Q3: Why do you only serve breakfast until 11:00?</h3><br />
							<small>Items on our breakfast menu are cooked slightly differently and at a different temperature from items on our main menu.</small>
                        </p>	
					<hr />
                        <p>
                            <h3>Q4: How can I create Ticket There?</h3><br />
							<small>You can just go to View Ticket and create the Ticket from ther.</small>
                        </p>
					<hr />
					
					
                        <p>
                            <h3>Q5: How can I create Ticket There?</h3><br />
							<small>You can just go to View Ticket and create the Ticket from ther.</small>
                        </p>
						
					<hr />
					
                        <p>
                            <h3>Q6: Are McDonald's veggie goujons gluten free?</h3><br />
							<small>Yes, but only without the bun of course. The  Pepper & Pesto Goujon itself has no gluten containing ingredients and is cooked in a separate fryer.</small>
                        </p>	
					<hr />
						
                        <p>
                            <h3>Q7: Why do you only serve breakfast until 11:00?</h3><br />
							<small>Items on our breakfast menu are cooked slightly differently and at a different temperature from items on our main menu.</small>
                        </p>	
					<hr />
                        <p>
                            <h3>Q8: How can I create Ticket There?</h3><br />
							<small>You can just go to View Ticket and create the Ticket from ther.</small>
                        </p>
					<hr />


                        <p>
                            <h3>Q9: How can I create Ticket There?</h3><br />
							<small>You can just go to View Ticket and create the Ticket from ther.</small>
                        </p>
						
					<hr />
					
                        <p>
                            <h3>Q10: Are McDonald's veggie goujons gluten free?</h3><br />
							<small>Yes, but only without the bun of course. The  Pepper & Pesto Goujon itself has no gluten containing ingredients and is cooked in a separate fryer.</small>
                        </p>	
					<hr />
						
                        <p>
                            <h3>Q11: Why do you only serve breakfast until 11:00?</h3><br />
							<small>Items on our breakfast menu are cooked slightly differently and at a different temperature from items on our main menu.</small>
                        </p>	
					<hr />
                        <p>
                            <h3>Q12: How can I create Ticket There?</h3><br />
							<small>You can just go to View Ticket and create the Ticket from ther.</small>
                        </p>
					<hr />
					
					
                        <p>
                            <h3>Q13: How can I create Ticket There?</h3><br />
							<small>You can just go to View Ticket and create the Ticket from ther.</small>
                        </p>
						
					<hr />
					
                        <p>
                            <h3>Q14: Are McDonald's veggie goujons gluten free?</h3><br />
							<small>Yes, but only without the bun of course. The  Pepper & Pesto Goujon itself has no gluten containing ingredients and is cooked in a separate fryer.</small>
                        </p>	
					<hr />
						
                        <p>
                            <h3>Q15: Why do you only serve breakfast until 11:00?</h3><br />
							<small>Items on our breakfast menu are cooked slightly differently and at a different temperature from items on our main menu.</small>
                        </p>	
					<hr />
                        <p>
                            <h3>Q16: How can I create Ticket There?</h3><br />
							<small>You can just go to View Ticket and create the Ticket from ther.</small>
                        </p>
					
					
					
                    </div>
                    </div>
                </div>
            </div>
        );
}

export default Profile;
