import * as React from 'react';

import { ButtonGroup, Button } from '@progress/kendo-react-buttons';
import { DateRangePicker } from '@progress/kendo-react-dateinputs';

import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Checkbox } from '@progress/kendo-react-inputs';

import { useLocalization } from '@progress/kendo-react-intl';
import { filterBy } from '@progress/kendo-data-query';

import { Upload } from '@progress/kendo-react-upload';

import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { Error } from '@progress/kendo-react-labels';
import { Input } from '@progress/kendo-react-inputs';
import { TextArea } from '@progress/kendo-react-inputs';

import { DateTimePicker } from '@progress/kendo-react-dateinputs';
import { provideIntlService } from '@progress/kendo-react-intl';

import { Grid, Column, ColumnMenu } from './../components/Grid';

import { FullNameCell, FlagCell, OnlineCell, RatingCell, EngagementCell, CurrencyCell } from './../components/GridCells';


 class ViewTicket extends React.Component { 



	
 
  
	
 constructor(props) {
		// for the login check system here
		const checklogin = localStorage.getItem('custid');
			if (typeof checklogin === "undefined") {
				window.location.href="/#login1"; 				
			} else {
				if(checklogin!="" && checklogin!=null) { } else {
						window.location.href="/#login"; 
					}
			}
		// for the login check system here
	 const ticketnumber  = localStorage.getItem('ticketnumber');
		document.title="View Ticket Number "+ticketnumber;
    super(props);


			
		this.state = {
			tocustomer		:	{ name: 'Select Customer', custid: 0 },
			tocreateddate	:	 new Date() ,
			toticketstatus	:	 'New' ,
			toissuetype		:	'Technical',
			usertype		:	localStorage.getItem('usertype'),
			createremail	:	localStorage.getItem('email'),
			tochannel		:	 "Email" ,
			allcustomers	:	[],
			allissuetype	:	["Technical","Sales","Others"],
			allchannel		:	["Email","Phone Call","Manual"],
			allticketstatus	:	["Draft","New","InProcess ","Closed","Deleted"],
			createmessage	:	"",
			formtitle		:	"Update Ticket",
		};
		
			this.handleSubmit = this.handleSubmit.bind(this);
  }
  
  
  componentDidMount()
	{
		this.getticketdetails();		
		this.getcustomers();		
			// setTimeout(function(){ },1500);
	}	 

	
	handleChange = (event) => { this.setState({ tocustomer: event.target.value }); } 
	
	
	handlechangeissuetype = (event) => { this.setState({ toissuetype: event.target.value }); }
	handlechangeticketstatus = (event) => { this.setState({ toticketstatus: event.target.value }); }
	handlechangechannel = (event) => { this.setState({ tochannel: event.target.value }); }

   getticketdetails = () => {
	   
	   const ticketnumber  = localStorage.getItem('ticketnumber');
	   
		let postData = new FormData();
				postData.append('ticketnumber', this.getarrayobject(ticketnumber));

		 const requestOptions 	=	{
							method	:	'POST',
							body	:	postData
						}; 
		 
		 fetch(global.config.apiurls.ticketdetails, requestOptions)
		 .then(response => response.json())
		 .then(response => {
				
				const ticketdata = response.data;
					const asas = new Date(ticketdata.createddate);
				// planneddate deliverydate   reporteddate   createddate	
				
				
var edit_reporteddate = ""; if(ticketdata.reporteddate!="") { edit_reporteddate	=	new Date(ticketdata.reporteddate); }
var edit_createddate = ""; if(ticketdata.createddate!="") { edit_createddate	=	new Date(ticketdata.createddate); }
var edit_deliverydate = ""; if(ticketdata.deliverydate!="") { edit_deliverydate	=	new Date(ticketdata.deliverydate); }
var edit_planneddate = ""; if(ticketdata.planneddate!="") { edit_planneddate	=	new Date(ticketdata.planneddate); }
				
		this.setState({
			edit_createddate	:	 edit_createddate,
			edit_reporteddate	:	 edit_reporteddate,
			edit_deliverydate	:	 edit_deliverydate,
			edit_planneddate	:	 edit_planneddate,
			tocustomer			:	{ name:ticketdata.name, custid: ticketdata.forcustomer },
			edit_ticketnumber	:	ticketdata.ticketnumber,
			edit_summary		:	ticketdata.summary,
			edit_description	:	ticketdata.description,
			edit_busipart		:	ticketdata.busipart,
			edit_maincontemail	:	ticketdata.maincontemail,
			edit_assigneeemail	:	ticketdata.assigneeemail,
			edit_comments		:	ticketdata.comments,
			edit_emails			:	ticketdata.emails,
			edit_resolutions	:	ticketdata.resolutions, 
			edit_update_comment	:	ticketdata.update_comment,
			toticketstatus		:	ticketdata.ticketstatus,
			toissuetype			:	ticketdata.issuetype,
			createremail		:	ticketdata.creatoremail,
			tochannel			:	ticketdata.tochannel,
		});
			
					
				
			})
		.catch(err => {
			console.log(err);
				 this.setState({
					createmessage: <Error>There is an problem, Submitting your request. Please try again later.</Error>
				  });
		});
	}
	   
   getcustomers = () => {
				fetch(global.config.apiurls.listcustomer)
				  .then(res => res.json())
				  .then(
					(result) => {
					  this.setState({
							allcustomers	:	result.data
					  });
					},
					(error) => {
					  this.setState({
						isLoaded: true,
						error
					  });
					}
				  )
					
				}
				
getdatetimevalue(datevaluehere)
	{
		if (typeof datevaluehere === "undefined") {
			return "";	
		} else {
			return datevaluehere.toLocaleString('tr-TR');	
		}
	}
	
getarrayobject(arrayobject)
	{
		if (typeof arrayobject === "undefined") {
			return "";	
		} else {
			return arrayobject;	
		}
	}
	
handleSubmit(dataItem)
	{
		 // creates entity 
		 
		 this.setState({
            createmessage: "Submitting your Request.."
          });
		 
		  const { tocustomer,toissuetype,toticketstatus,tochannel } = this.state;
		  
		    let postData = new FormData();
			
postData.append('ticketnumber', this.getarrayobject(dataItem.ticketnumber));
postData.append('summary', this.getarrayobject(dataItem.summary));
postData.append('description', this.getarrayobject(dataItem.description));

postData.append('forcustomer', this.getarrayobject(tocustomer.custid));

if(tocustomer.custid=='0')
	{		
		this.setState({
						createmessage: <Error>Please Select Customer from the List.</Error>
					  });
			return 0;		  
	}

postData.append('busipart', this.getarrayobject(dataItem.busipart));  

postData.append('maincontemail', this.getarrayobject(dataItem.maincontemail));
		

			postData.append('issuetype', this.getarrayobject(toissuetype));
			postData.append('ticketstatus', this.getarrayobject(toticketstatus));
				
			
		postData.append('createddate', this.getdatetimevalue(dataItem.createddate));
		postData.append('reporteddate', this.getdatetimevalue(dataItem.reporteddate));
				postData.append('emails', this.getarrayobject(dataItem.emails));
		postData.append('deliverydate', this.getdatetimevalue(dataItem.deliverydate));
		postData.append('planneddate', this.getdatetimevalue(dataItem.planneddate));
		
		
		   
		
			postData.append('comments', this.getarrayobject(dataItem.comments)); 
			postData.append('update_comment', this.getarrayobject(dataItem.update_comment)); 
			postData.append('resolutions', this.getarrayobject(dataItem.resolutions)); 
			
			
			postData.append('creatoremail', this.getarrayobject(dataItem.creatoremail)); 
			postData.append('creatoremail', this.state.createremail); 
			postData.append('assigneeemail', this.getarrayobject(dataItem.assigneeemail)); 
		
			postData.append('tochannel', this.getarrayobject(tochannel));
		
					if (typeof dataItem.sendemail === "undefined")
						{
							postData.append('sendemail', 0);  
						} else {
							postData.append('sendemail', 0);  
								if(dataItem.sendemail)
									postData.append('sendemail', 1);  
						}	
		 
		 const requestOptions 	=	{
							method	:	'POST',
							body	:	postData
						}; 
		 
		 fetch(global.config.apiurls.createticket, requestOptions)
		 .then(response => response.json())
		 .then(response => {
				this.setState({
					createmessage: <Error>There is an problem, Submitting your request. Please try again later.</Error>
				  });
			})
		.catch(err => {
			console.log(err);
				 this.setState({
					createmessage: <Error>There is an problem, Submitting your request. Please try again later.</Error>
				  });
		});
		
		// alert(JSON.stringify(dataItem, null, 2));
	}

     render() {
		 
	
		 const today = new Date();
	
		 
    const { createmessage,formtitle,allcustomers,allissuetype,allticketstatus } = this.state;
    
      return (
       
        <div className="card-container" >
        <div className="card-component" >
			 <Form
            onSubmit={this.handleSubmit}
            render={(formRenderProps) => (
                <FormElement style={{maxWidth: 650}}>
                    <fieldset className={'k-form-fieldset'}>
                        
						<legend className={'k-form-legend'}>
							{formtitle}
						</legend>
						
                        <div className="form-group">
                            <Field name={'ticketnumber'} component={Input} label={'Ticket No (Auto Generated)'} value={this.state.edit_ticketnumber} disabled={true} />
                        </div>
						
	<div className="form-group">
		<Field 
				name={'summary'} 
				value={this.state.edit_summary} 
				onChange={(e) => this.setState({ edit_summary: e.target.value }) } 	
		component={Input} 
		label={'Summary*'} />
	</div>
						

                        <div className="form-group  mt-10">
							<label title={'Detailed description of the ticket'}>Description*</label>
                           
						   <Field rows={5} name={"description"} type={"text"} component={TextArea} label={"Email"} required={true} 
						   value={this.state.edit_description} 
						onChange={(e) => this.setState({ edit_description: e.target.value }) }
						   />
						  
                        </div>
						
                        <div className="form-group mt-10">
                            <label>Customer*</label>
							<Field
								component={DropDownList} 
								name={'forcustomer'} 
								required={true}
								data={allcustomers} 
								textField="name"
								dataItemKey="custid"
								value={this.state.tocustomer}
								onChange={this.handleChange}
							/>
							
                        </div>
						
						
                        <div className="form-group">
                            <Field name={'busipart'} component={Input} label={'Customer\'s Business Partner\'s Name'}
								value={this.state.edit_busipart} 
								onChange={(e) => this.setState({ edit_busipart: e.target.value }) }
							/>
                        </div>
						
                        <div className="form-group">
                            <Field name={'maincontemail'} component={Input} label={'Ticket Main Contact/ Reporter (Email)*'} 
								value={this.state.edit_maincontemail} 
								onChange={(e) => this.setState({ edit_maincontemail: e.target.value }) }
							/>
                        </div>
						
						
                        <div className="form-group mt-10">
							<label>Issue Type*</label>
							<Field
								name={'forissuetype'} 
								required={true}
								component={DropDownList} 
								data={allissuetype} 
								value={this.state.toissuetype}
								onChange={this.handlechangeissuetype}
							/>	
                        </div>
						
						
                        <div className="form-group mt-10">
							<label>Status*</label>
							<Field
								component={DropDownList} 
								name={'forticketstatus'} 
								data={allticketstatus} 
								value={this.state.toticketstatus}
								onChange={this.handlechangeticketstatus}
							/>
							
                        </div>
						
						<label className="form-group k-form-field">
							<span>Created Date</span>
								<Field
									name='createddate'
									component={DateTimePicker}
									width="auto"
									required={true}
									defaultValue={this.state.tocreateddate}
									value={this.state.edit_createddate}
									min={today}
								/>
						</label>
						
						<label className="form-group  k-form-field">
							<span>Reported Date</span>
								<Field
									name="reporteddate"
									component={DateTimePicker}
									value={this.state.edit_reporteddate}
									width="100%"
										required={true}
										min={today}
								/>
						</label>
						
						
						
                        <div className="form-group">
                            <Field name={'emails'} component={Input} label={'Email\'s (Comma Seperated)'}
								value={this.state.edit_emails} 
								onChange={(e) => this.setState({ edit_emails: e.target.value }) }
							/>
                        </div>
						
						
						
						<label className="form-group  k-form-field">
							<span>Requested Delivery Date</span>
								<Field
									name='deliverydate'
									component={DateTimePicker}
									width="100%"
									required={false}
									value={this.state.edit_deliverydate}
									min={today}
								/>
						</label>
						
						<label className="form-group  k-form-field">
							<span>Planned Due Date</span>
								<Field
									name='planneddate'
									component={DateTimePicker}
									width="100%"
									value={this.state.edit_planneddate}
									required={false}
									min={today}
								/>
						</label>
						
						
                        <div className="form-group  mt-10">
							<label>Comments on Create Ticket</label>
                           
						   <Field rows={5} name={"comments"} type={"text"} component={TextArea} label={"Comments"} required={true}
								value={this.state.edit_comments} 
								onChange={(e) => this.setState({ edit_comments: e.target.value }) }
							
						   
						   />
						  
                        </div>
						
						
							 
						<div className="form-group  mt-10">
							<label>Resolutions</label>
							<Field rows={5} name={"resolutions"} type={"text"} component={TextArea} label={"Resolutions"} required={false}
								value={this.state.edit_resolutions} 
								onChange={(e) => this.setState({ edit_resolutions: e.target.value }) }
						   />
                        </div>
						
						
                        <div className="form-group  mt-10">
							<label>Comments on Update Ticket</label>
							<Field rows={5} name={"update_comment"} type={"text"} component={TextArea} label={"Comments on Update Ticket"} required={true}
								value={this.state.edit_update_comment} 
								onChange={(e) => this.setState({ edit_update_comment: e.target.value }) }
						   />
                        </div>
						
						
						
                        
						
						
					 
                        <div className="form-group  mt-10">
							<label>Attachment</label>
                           
						   <Field component={Upload} 
								batch={false}
								multiple={true}
								defaultFiles={[]}
								withCredentials={false}
						   />
						  
                        </div>	
						
						
						
                        <div className="form-group">
                            <Field name={'creatoremail'} component={Input} label={'Creator email'} value={this.state.createremail} />
                        </div>
						
                        <div className="form-group">
                            <Field name={'assigneeemail'} component={Input} 
								label={'Assigne email'} 
								value={this.state.edit_assigneeemail} 
								onChange={(e) => this.setState({ edit_assigneeemail: e.target.value }) }
								/>
                        </div>
						
						
                        <div className="form-group mt-10">
							<label>Channel of Support*</label>
							<Field
								component={DropDownList} 
								name={'forchannel'} 
								required={true} 
								data={this.state.allchannel} 
								value={this.state.tochannel}
								onChange={this.handlechangechannel}
							/>
							
                        </div>
						
						<div className="form-group mt-10">
						
							<Field 
								component={Checkbox} 
								name={'sendemail'} 
								required={false} 
								label={'Send email on Ticket'}
							/>
                        </div>
						
						<div className="form-group ">
						
							<Field 
								component={Checkbox} 
								name={'closeticket'} 
								required={false} 
								label={'Close Ticket'}
							/>
                        </div>
						
						
                    </fieldset>
					{createmessage}
                    <div className="k-form-buttons">
                        <button
                            type={'submit'}
                            className="k-button k-primary"
                            disabled={!formRenderProps.allowSubmit}
                        >
                            Update Ticket
                        </button>
                    </div>
                </FormElement>
            )}
        />
		
		</div>
		</div>
	   
      );
    
  } 
} 

export default ViewTicket;