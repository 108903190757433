export const teams = [
    {
        'teamID': 1,
        'teamName': 'Open Tickets',
        'teamColor': '#FF6358'
    },
    {
        'teamID': 2,
        'teamName': 'Unnswered Tickets',
        'teamColor': '#F7C62F'
    },
    {
        'teamID': 3,
        'teamName': 'Closed Ticket',
        'teamColor': '#55AB1D'
    },
    {
        'teamID': 4,
        'teamName': 'Answered Ticket',
        'teamColor': '#28B4C8'
    }
];