module.exports = global.config = {
	apiurls : {
            logincss		: 	"https://reactprj.sjainventures.com/login.css",
            adminlogin		: 	"https://abhi.sjainventures.com/turkryapi/api/adminlogin",
            customerlogin	: 	"https://abhi.sjainventures.com/turkryapi/api/customerlogin",
            agentlogin		: 	"https://abhi.sjainventures.com/turkryapi/api/agentlogin",
            getsmtpsettings	: 	"https://abhi.sjainventures.com/turkryapi/api/getsmtpsettings",
            smtpsettings	: 	"https://abhi.sjainventures.com/turkryapi/api/smtpsettings",
            createagent		: 	"https://abhi.sjainventures.com/turkryapi/api/createagent",
            listagent		: 	"https://abhi.sjainventures.com/turkryapi/api/allagents",
            createcustomer	: 	"https://abhi.sjainventures.com/turkryapi/api/createcustomer",
            listcustomer	: 	"https://abhi.sjainventures.com/turkryapi/api/allcustomers",
            updateticket	: 	"https://abhi.sjainventures.com/turkryapi/api/updateticket",
            createticket	: 	"https://abhi.sjainventures.com/turkryapi/api/createticket",
            allticket		:	"https://abhi.sjainventures.com/turkryapi/api/manageticket",
            ticketdetails	:	"https://abhi.sjainventures.com/turkryapi/api/ticketdetails"
        },
        // rest of your translation object
	website : {
            name: "Ticket Management System",
            url: "slkfh.saf"
        }
};