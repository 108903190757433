import React from 'react';

import './App.scss';

import { HashRouter, Switch, Route } from 'react-router-dom';
import Dashboard from './pages/Dashboard.jsx';
// import Planning from './pages/Planning.jsx';
import AdminLogin from './pages/AdminLogin.jsx';
import AgentLogin from './pages/AgentLogin.jsx';
import Login from './pages/Login.jsx';
import Logout from './pages/Logout.jsx';
import Profile from './pages/Profile.jsx';
// import ManageAdmin from './pages/ManageAdmin.jsx';
import CreateTicket from './pages/CreateTicket.jsx';
import ViewTicket from './pages/ViewTicket.jsx';
import ManageTicket from './pages/ManageTicket.jsx';
import ManageCustomer from './pages/ManageCustomer.jsx';
import ManageAgent from './pages/ManageAgent.jsx';
import KnowledgeManagement from './pages/KnowledgeManagement.jsx';
import Settings from './pages/Settings.jsx';
// import Info from './pages/Info.jsx';
import DrawerRouterContainer from './components/DrawerRouterContainer.jsx';
import { AppContext } from './AppContext';
import { countries } from './resources/countries';
import { IntlProvider, load, LocalizationProvider, loadMessages } from '@progress/kendo-react-intl';

import likelySubtags from 'cldr-core/supplemental/likelySubtags.json';
import currencyData from 'cldr-core/supplemental/currencyData.json';
import weekData from 'cldr-core/supplemental/weekData.json';

import frNumbers from 'cldr-numbers-full/main/fr/numbers.json';
import frLocalCurrency from 'cldr-numbers-full/main/fr/currencies.json';
import frCaGregorian from 'cldr-dates-full/main/fr/ca-gregorian.json';
import frDateFields from'cldr-dates-full/main/fr/dateFields.json';

import usNumbers from 'cldr-numbers-full/main/en/numbers.json';
import usLocalCurrency from 'cldr-numbers-full/main/en/currencies.json';
import usCaGregorian from 'cldr-dates-full/main/en/ca-gregorian.json';
import usDateFields from'cldr-dates-full/main/en/dateFields.json';

import esNumbers from 'cldr-numbers-full/main/es/numbers.json';
import esLocalCurrency from 'cldr-numbers-full/main/es/currencies.json';
import esCaGregorian from 'cldr-dates-full/main/es/ca-gregorian.json';
import esDateFields from'cldr-dates-full/main/es/dateFields.json';

import { enMessages } from './messages/en-US';
import { frMessages } from './messages/fr';
import { esMessages } from './messages/es';

load(
    likelySubtags,
    currencyData,
    weekData,
    frNumbers,
    frLocalCurrency,
    frCaGregorian,
    frDateFields,
    usNumbers,
    usLocalCurrency,
    usCaGregorian,
    usDateFields,
    esNumbers,
    esLocalCurrency,
    esCaGregorian,
    esDateFields
);

loadMessages(esMessages, 'es');
loadMessages(frMessages, 'fr');
loadMessages(enMessages, 'en-US');

const apiurlscreate	=	"createurlhere";
const apiurlupdate	=	"apiurlupdate";



const App = () => {
    const [contextState, setContextState] = React.useState({
        localeId: 'en-US',
        firstName: 'Annu',
        lastName: 'Upadhyay',
        middleName: '',
        email: 'annu@gmail.com',
        phoneNumber: '(+91) 9876543210',
        avatar: null,
        country: countries[100].name,
        isInPublicDirectory: true,
        biography: '',
        password:'123',
        teamId: 1
    });
    const onLanguageChange = React.useCallback(
        (event) => { setContextState({...contextState, localeId: event.value.localeId}) },
        [contextState, setContextState]
    );
    const onProfileChange = React.useCallback(
        (event) => {
            setContextState({...contextState, ...event.dataItem});
        },
        [contextState, setContextState]
    );
    return (
        <div className="App">
            <LocalizationProvider language={contextState.localeId}>
                <IntlProvider locale={contextState.localeId}>
                    <AppContext.Provider value={{...contextState, onLanguageChange, onProfileChange}}>
                        <HashRouter>
                            <DrawerRouterContainer>
                                <Switch>
	<Route exact={true} path="/" component={Dashboard} />
	<Route exact={true} path="/Logout" component={Logout} />
	<Route exact={true} path="/agent/login" component={AgentLogin} />
	<Route exact={true} path="/admin/login" component={AdminLogin} />
	<Route exact={true} path="/login" component={Login} />
	
	<Route exact={true} path="/profile" component={Profile} />
   
	<Route exact={true} path="/manageticket" component={ManageTicket} />
	<Route exact={true} path="/createticket" component={CreateTicket} />
	<Route exact={true} path="/viewticket" component={ViewTicket} />
	<Route exact={true} path="/managecustomer" component={ManageCustomer} />
	<Route exact={true} path="/manageagents" component={ManageAgent} />
	<Route exact={true} path="/knowledgemanagment" component={KnowledgeManagement} />
	<Route exact={true} path="/settings" component={Settings} />
                                </Switch>
                            </DrawerRouterContainer>
                        </HashRouter>
                    </AppContext.Provider>
                </IntlProvider>
            </LocalizationProvider>
        </div>
    );
}

export default App;
